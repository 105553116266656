import axios from "axios";

export default {
  async getFatture(customerID: string) {
    const data = new FormData();
    data.append("where[documenti_contabilita_customer_id]", customerID);
    data.append("orderby", "documenti_contabilita_data_emissione");
    data.append("orderdir", "desc");

    const response = await axios.post(`rest/v1/search/documenti_contabilita`, data, {
      headers: {
        Authorization: `${process.env.VUE_APP_TOKEN}`,
      },
    });

    //console.log(response);
    return response.data;
  },
};
